import { useContext, useEffect } from "react";
import { Source, Layer } from "react-map-gl";
import colors from "../../colors";

import appContext from "../../context/appContext";

import { useGeosIntersectingBufferedGeo } from "@headwaters-economics/web-shared";

const BufferToolLayer = () => {
  const {
    searchFeature,
    analysisType,
    visibleGeoLayer,
    bufferDistance,
    setGeosWithinDist,
  } = useContext(appContext);

  const intersectResult = useGeosIntersectingBufferedGeo({
    inGeoID: analysisType === "withinDist" ? searchFeature.id : null,
    outGeoType: "he-" + visibleGeoLayer,
    buffer: bufferDistance,
    returnBufferedGeometry: true,
    inGeoTable:'he.geos_usfws'
  });

  useEffect(
    () => {
      if (
        intersectResult.status === "fetched" 
        // &&
        // analysisType === "withinDist" &&
        // isProcessing
      ) {
        setGeosWithinDist(
          intersectResult.geosIntersectingGeo,
          intersectResult.bufferedGeometry
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intersectResult.geosIntersectingGeo]
  );

  return (
    <>
      <Source
        id={"bufferedGeometrySrc"}
        type="geojson"
        data={{ type: "Feature", geometry: intersectResult.bufferedGeometry }}
      />

      <Layer
        id={"bufferedGeometryLyr"}
        source={"bufferedGeometrySrc"}
        type="line"
        paint={{
          "line-color": colors.buffer,
          "line-width": 2,
        }}
      />
    </>
  );
};

export default BufferToolLayer;
