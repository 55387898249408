import {
  Box,
  Button,
  DataTable,
  Footer,
  Grid,
  Header,
  Heading,
  Layer,
  RangeInput,
  Stack,
  Text,
} from "grommet";
import {
  Edit as EditBenchmarkIcon,
  FormClose as RemoveGeoIcon,
  Ascend as SortAscendingIcon,
  Descend as SortDescendingIcon,
} from "grommet-icons";
import { useContext, useEffect, useState, useRef, useCallback } from "react";
import appContext from "../context/appContext";
import econDataTypes from "../assets/econDataTypes";
import numberFormatters from "../numberFormatters";
import { ModalButton } from "./Buttons";
import LocationPicker from "@headwaters-economics/web-shared/components/LocationPicker";

const headerHeight = 65;
const footerHeight = 35;
const nameColumnWidth = "300px";
const valueLeftPadding = "200px";
const halfSlidingThumbWidth = "5px"; // allows for better alignment of meters and reference lines

const TableHeader = (props) => {
  return <Box height={headerHeight + "px"}>{props.children}</Box>;
};
const TableFooter = (props) => {
  return <Box height={footerHeight + "px"}>{props.children}</Box>;
};

const GrommetChart = () => {
  const {
    selectedGeos,
    epsData,
    selectedSocioEconomicType,
    breakPoint,
    benchmarkGeo,
    removeGeo,
    setBreakpoint,
  } = useContext(appContext);

  const [sortInfo, set_sortInfo] = useState(null);

  const [tableBodyData, set_tableBodyData] = useState([]);
  const [maxValue, set_maxValue] = useState(null);
  const [isBenchmarkEditorVisible, set_isBenchmarkEditorVisible] =
    useState(false);
  const [currentEconDataType, set_currentEconDataType] = useState(
    econDataTypes[selectedSocioEconomicType]
  );
  const [renderedHeight, set_renderedHeight] = useState(0);
  const elementRef = useRef(null);

  const updateTableData = useCallback(() => {
    let tableData = selectedGeos.map((sg) => {
      const value = !epsData[sg.id]
        ? null
        : epsData[sg.id][selectedSocioEconomicType].pct ||
          epsData[sg.id][selectedSocioEconomicType].value;
      const moe = !epsData[sg.id]
        ? null
        : epsData[sg.id][selectedSocioEconomicType].pct_moe ||
          epsData[sg.id][selectedSocioEconomicType].moe;
      return {
        value: value,
        name: sg.name,
        id: sg.id,
        renderedName: <Text size="small">{sg.name}</Text>,
        renderedValue: (
          <ValueStringWithMeter
            value={value}
            moe={moe}
            isBenchmark={false}
            currentEconDataType={currentEconDataType}
            maxValue={maxValue}
            breakPoint={breakPoint}
          />
        ),
      };
    });
    if (sortInfo) {
      if (sortInfo.on === "name" ) {
        if (sortInfo.direction === "asc") {
          tableData.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
          );
        }
        else {
          tableData.sort((a, b) =>
            b.name > a.name ? 1 : a.name > b.name ? -1 : 0
          );
        }
      }
      if (sortInfo.on === "value" ) {
        if (sortInfo.direction === "asc") {
          tableData.sort((a, b) =>
            a.value > b.value ? 1 : b.value > a.value ? -1 : 0
          );
        }
        else {
          tableData.sort((a, b) =>
            b.value > a.value ? 1 : a.value > b.value ? -1 : 0
          );
        }
      }
    }

    set_tableBodyData(tableData);
  }, [
    selectedGeos,
    epsData,
    selectedSocioEconomicType,
    breakPoint,
    maxValue,
    currentEconDataType,
    sortInfo
  ]);

  useEffect(() => {
    updateTableData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateTableData();
  }, [
    selectedGeos,
    selectedSocioEconomicType,
    breakPoint,
    benchmarkGeo,
    maxValue,
    updateTableData,
  ]);

  useEffect(() => {
    setBreakpoint(
      epsData[benchmarkGeo.id][selectedSocioEconomicType].pct ||
        epsData[benchmarkGeo.id][selectedSocioEconomicType].value
    );
    // eslint-disable-next-line
  }, [selectedSocioEconomicType]);

  ///////////////////////////////
  // Calculate the rendered height of the table body for use in drawing the reference lines
  useEffect(() => {
    setTimeout(() => {
      set_renderedHeight(elementRef.current.offsetHeight);
    }, 10);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      set_renderedHeight(elementRef.current.offsetHeight);
    }, 10);
  }, [selectedGeos.length]);
  //
  ///////////////////////////////

  ///////////////////////////////
  // Update the maxValue for the selected geos and data type
  useEffect(() => {
    const valField =
      econDataTypes[selectedSocioEconomicType].metric === "percent"
        ? "pct"
        : "value";
    const moeField =
      econDataTypes[selectedSocioEconomicType].metric === "percent"
        ? "pct_moe"
        : "moe";

    let _max_value = 0;
    let allGeoIds = selectedGeos.map((geo) => geo.id);
    if (epsData[benchmarkGeo.id]) {
      allGeoIds.push(benchmarkGeo.id);
    }

    allGeoIds.forEach((geoID) => {
      if (epsData[geoID]) {
        const dataForGeo = epsData[geoID][selectedSocioEconomicType];
        const maxForGeo = dataForGeo[valField] + dataForGeo[moeField];
        if (maxForGeo > _max_value) {
          _max_value = maxForGeo;
        }
      }
    });

    if (
      econDataTypes[selectedSocioEconomicType].metric === "percent" &&
      _max_value > 1
    ) {
      set_maxValue(1);
    }

    if (_max_value !== maxValue) {
      set_maxValue(_max_value);
    }
  }, [
    selectedGeos,
    epsData,
    selectedSocioEconomicType,
    benchmarkGeo,
    maxValue,
  ]);
  //
  ////////////////////////////////

  useEffect(() => {
    set_currentEconDataType(econDataTypes[selectedSocioEconomicType]);
  }, [selectedSocioEconomicType]);

  const NameHeader = () => {
    return (
      <TableHeader>
        <Box direction="row" gap="small">
          <Text>Selected Areas</Text>
          <SortButton
            dir={
              !sortInfo || sortInfo.on !== "name"
                ? "unsorted"
                : sortInfo.direction
            }
            onClick={() => {
              set_sortInfo({
                on: "name",
                direction:
                  sortInfo &&
                  sortInfo.on === "name" &&
                  sortInfo.direction === "asc"
                    ? "desc"
                    : "asc",
              });
            }}
          />
        </Box>
      </TableHeader>
    );
  };

  const ComparisonLocationLabel = useCallback(() => {
    return (
      <Box direction="row" gap="small">
        <Button
          plain
          icon={<EditBenchmarkIcon />}
          onClick={() => set_isBenchmarkEditorVisible(true)}
        />
        <Text size="small">
          {benchmarkGeo.label} <Text size="small">(Comparison Location)</Text>
        </Text>
      </Box>
    );
  }, [benchmarkGeo]);

  const ValueHeader = useCallback(() => {
    return (
      <TableHeader>
        <Box direction="row" gap="small">
          <Text>{econDataTypes[selectedSocioEconomicType].label}</Text>
          <SortButton
            dir={
              !sortInfo || sortInfo.on !== "value"
                ? "unsorted"
                : sortInfo.direction
            }
            onClick={() => {
              set_sortInfo({
                on: "value",
                direction:
                  sortInfo &&
                  sortInfo.on === "value" &&
                  sortInfo.direction === "asc"
                    ? "desc"
                    : "asc",
              });
            }}
          />
        </Box>
        <Box direction="row" pad={{ left: valueLeftPadding }}>
          {maxValue && (
            <BreakpointSlider
              breakPoint={breakPoint}
              metric={currentEconDataType.metric}
              maxValue={maxValue}
              setBreakpoint={setBreakpoint}
              tableBodyHeight={renderedHeight - footerHeight - headerHeight}
              benchmarkValue={
                !epsData[benchmarkGeo.id]
                  ? null
                  : epsData[benchmarkGeo.id][selectedSocioEconomicType].pct ||
                    epsData[benchmarkGeo.id][selectedSocioEconomicType].value
              }
            />
          )}
        </Box>
      </TableHeader>
    );
    // eslint-disable-next-line
  }, [
    econDataTypes,
    selectedSocioEconomicType,
    maxValue,
    breakPoint,
    renderedHeight,
    epsData,
    benchmarkGeo,
    currentEconDataType,
    set_sortInfo,
    sortInfo,
  ]);

  const BenchmarkValue = useCallback(() => {
    if (!epsData[benchmarkGeo.id]) return "";
    return (
      <ValueStringWithMeter
        value={
          !epsData[benchmarkGeo.id]
            ? null
            : epsData[benchmarkGeo.id][selectedSocioEconomicType].pct ||
              epsData[benchmarkGeo.id][selectedSocioEconomicType].value
        }
        moe={
          !epsData[benchmarkGeo.id]
            ? null
            : epsData[benchmarkGeo.id][selectedSocioEconomicType].pct_moe ||
              epsData[benchmarkGeo.id][selectedSocioEconomicType].moe
        }
        isBenchmark={true}
        currentEconDataType={currentEconDataType}
        maxValue={maxValue}
        breakPoint={breakPoint}
      />
    );
  }, [
    epsData,
    benchmarkGeo,
    selectedSocioEconomicType,
    currentEconDataType,
    maxValue,
    breakPoint,
  ]);

  return (
    <Box ref={elementRef}>
      <DataTable
        primaryKey="id"
        background={["white", "light-2"]}
        columns={[
          {
            property: "renderedName",
            header: <NameHeader />,
            primary: true,
            footer: (
              <TableFooter>
                <ComparisonLocationLabel />
              </TableFooter>
            ),
            size: nameColumnWidth,
          },
          {
            property: "renderedValue",
            header: (
              <TableHeader>
                <ValueHeader />
              </TableHeader>
            ),
            footer: (
              <TableFooter>
                <BenchmarkValue />
              </TableFooter>
            ),
            // render: valueFormatter,
          },
          {
            property: "id",

            size: "30px",
            render: (datum) => {
              return (
                <Button
                  plain
                  icon={<RemoveGeoIcon />}
                  onClick={() => removeGeo(datum.id)}
                />
              );
            },
          },
        ]}
        size="30vh"
        data={tableBodyData}
      />
      {isBenchmarkEditorVisible && (
        <BenchmarkEditor
          set_isBenchmarkEditorVisible={set_isBenchmarkEditorVisible}
        />
      )}
    </Box>
  );
};

const ValueStringWithMeter = ({
  value,
  moe,
  isBenchmark,
  currentEconDataType,
  breakPoint,
  maxValue,
}) => {
  const valStr = numberFormatters[currentEconDataType.metric].format(value);
  const moeStr = numberFormatters[currentEconDataType.metric].format(moe);
  const aboveBreakPoint = value > breakPoint;

  return (
    <Grid fill columns={[valueLeftPadding, "auto"]}>
      <Box direction="row">
        <Text size="small">{valStr}</Text>
        <Text size="xsmall">&nbsp;&plusmn;&nbsp;{moeStr}</Text>
      </Box>
      {maxValue && (
        <GeoMeter
          value={value}
          moe={moe}
          max={maxValue}
          color={
            isBenchmark
              ? "lightgrey"
              : aboveBreakPoint
              ? currentEconDataType.color
              : currentEconDataType.color_lt
          }
        />
      )}
    </Grid>
  );
};

const BenchmarkEditor = ({ set_isBenchmarkEditorVisible }) => {
  const { benchmarkGeo, setBenchmark } = useContext(appContext);
  const [newBenchmarkGeo, set_newBenchmarkGeo] = useState(null);
  return (
    <Layer
      onEsc={() => set_isBenchmarkEditorVisible(false)}
      onClickOutside={() => set_isBenchmarkEditorVisible(false)}
    >
      <Box gap="small" width={"large"}>
        <Header pad={"xsmall"} justify="start" border="bottom">
          <EditBenchmarkIcon />
          <Heading>Edit Comparison Location</Heading>
        </Header>
        <Box pad={"small"} gap="small">
          <Text>Update comparison location to a state or county</Text>

          <LocationPicker
            border
            round="xsmall"
            geoTypes={["he-state", "he-county"]}
            placeholder={
              newBenchmarkGeo
                ? newBenchmarkGeo.label
                : "Current: " + benchmarkGeo.label
            }
            onSelection={(item) => {
              set_newBenchmarkGeo(item);
            }}
            width="auto"
          />
        </Box>
        <Footer pad={"xsmall"} border={"top"} justify="end">
          <ModalButton
            label="Save"
            disabled={!newBenchmarkGeo}
            onClick={() => {
              setBenchmark(newBenchmarkGeo);
              set_isBenchmarkEditorVisible(false);
            }}
          />
          <ModalButton
            label="Reset to United States"
            disabled={benchmarkGeo.id === "0"}
            onClick={() => {
              setBenchmark({ id: "0", label: "United States" });
              set_isBenchmarkEditorVisible(false);
            }}
          />
          <ModalButton
            label="Close"
            onClick={() => set_isBenchmarkEditorVisible(false)}
          />
        </Footer>
      </Box>
    </Layer>
  );
};

const GeoMeter = ({ value, moe, max, color }) => {
  const moeStops = [
    0,
    value - moe > 0 ? value - moe : 0,
    value,
    value + moe < max ? value + moe : max,
    max,
  ];
  const moeColumns = [
    100 * (moeStops[1] / max) + "%",
    100 * ((moeStops[2] - moeStops[1]) / max) + "%",
    100 * ((moeStops[3] - moeStops[2]) / max) + "%",
    "auto",
  ];
  return (
    <Box pad={{ left: halfSlidingThumbWidth, right: halfSlidingThumbWidth }}>
      <Stack fill>
        <Grid
          fill="horizontal"
          height={"21px"}
          columns={[100 * (value / max) + "%", "auto"]}
        >
          <Box background={color}></Box>
          <Box></Box>
        </Grid>
        <Grid
          fill="horizontal"
          margin={{ top: "8px" }}
          height={"4px"}
          columns={moeColumns}
        >
          <Box></Box>
          <Box background={"light-1"}></Box>
          <Box background={color}></Box>
          <Box></Box>
        </Grid>
      </Stack>
    </Box>
  );
};

const BreakpointSlider = ({
  breakPoint,
  metric,
  maxValue,
  setBreakpoint,
  tableBodyHeight,
  benchmarkValue,
}) => {
  const [sliderVal, set_sliderVal] = useState(100 * (breakPoint / maxValue));

  useEffect(() => {
    //check if the breakpoint has been changed externally
    if (100 * (breakPoint / maxValue) !== sliderVal) {
      set_sliderVal(100 * (breakPoint / maxValue));
    }
    // eslint-disable-next-line
  }, [breakPoint]);

  useEffect(() => {
    // debounce breakpoint setter
    const t = setTimeout(() => {
      setBreakpoint(maxValue * (sliderVal / 100));
    }, 100);

    return () => {
      clearTimeout(t);
    };
    // eslint-disable-next-line
  }, [sliderVal]);

  if (!metric) return null;

  const RefLine = ({
    color,
    topMargin,
    bottomMargin,
    leftMargin,
    dashSpacing,
  }) => {
    return (
      <Box margin={{ left: leftMargin }} height="0px" overflow={"visible"}>
        <svg
          height={tableBodyHeight + 10}
          width="100%"
          style={{ overflow: "visible", zIndex: 1 }}
        >
          <line
            style={{
              stroke: color,
              opacity: 0.5,
              strokeWidth: 2,
              strokeDasharray: dashSpacing,
              overflow: "visible",
            }}
            x1={0}
            y1={topMargin}
            x2={0}
            y2={tableBodyHeight + 10 - bottomMargin}
          />
        </svg>
      </Box>
    );
  };

  return (
    <Box fill>
      <Box
        margin={{ left: sliderVal + "%", right: 100 - sliderVal + "%" }}
        align="center"
      >
        <Text size="xsmall">
          {numberFormatters.compact[metric].format(
            maxValue * (sliderVal / 100)
          )}
        </Text>
      </Box>

      <RangeInput
        value={sliderVal}
        min={0}
        max={100}
        onChange={(event) => set_sliderVal(event.target.value)}
        step={0.1}
      />

      {/*  The box with padding keeps the svg lined up with center of the slider thumb */}
      <Box pad={{ left: halfSlidingThumbWidth, right: halfSlidingThumbWidth }}>
        {/* Reference line on the left of table body */}
        <RefLine
          color="grey"
          leftMargin={"0%"}
          dashSpacing={0}
          topMargin={17}
          bottomMargin={17}
        />
        {/* Reference line for the slider handle. */}
        <RefLine
          color="#3D7199"
          leftMargin={sliderVal + "%"}
          dashSpacing={4}
          topMargin={0}
          bottomMargin={15}
        />
        {/* Reference line on the benchmark */}
        <RefLine
          color="lightgrey"
          leftMargin={100 * (benchmarkValue / maxValue) + "%"}
          dashSpacing={4}
          topMargin={18}
          bottomMargin={0}
        />
      </Box>
    </Box>
  );
};

const SortButton = ({ dir, onClick }) => {
  const icon =
    dir === "unsorted" ? (
      <SortAscendingIcon color="light-3" />
    ) : dir === "asc" ? (
      <SortAscendingIcon color="dark-1" />
    ) : (
      <SortDescendingIcon color="dark-1" />
    );

  return <Button plain icon={icon} onClick={onClick} />;
};

export default GrommetChart;
