import { useContext, useState, useEffect } from "react";
import { DrawControl } from "@headwaters-economics/web-shared/components/Map";

import appContext from "../../context/appContext";
import { useGeosIntersectingGeometry } from "@headwaters-economics/web-shared";

const emptyFeatureCollection = {
  type: "FeatureCollection",
  features: [],
};

const primaryColor = "#D20C0C";

const DrawLayer = () => {
  const {
    analysisType,
    visibleGeoLayer,
    setGeosIntersectingGeometry,
  } = useContext(appContext);
  const [drawToolData, set_drawToolData] = useState(emptyFeatureCollection);



  const { geosIntersectingGeometry, status: geosIntersectingGeometryStatus } =
    useGeosIntersectingGeometry({
      geoType: "he-" + visibleGeoLayer,
      geometry:
        drawToolData.features.length > 0
          ? drawToolData.features[0].geometry
          : null,
    });

  useEffect(
    () => {
      if (
        geosIntersectingGeometryStatus === "fetched" &&
        analysisType === "drawPolygon"
      ) {
        setGeosIntersectingGeometry(geosIntersectingGeometry);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [geosIntersectingGeometry]
  );



  const onChange = (data) => {
    // timeout to avoid a strange mapbox-gl-draw error
    setTimeout(() => {
      set_drawToolData({ ...data, features: [data.features[0]] });
    }, 50);
  }

  
  return (
    <DrawControl
      displayControlsDefault={false}
      controls={{
        polygon: true,
        trash: true,
      }}
      defaultMode="draw_polygon"
      onCreate={onChange}
      onUpdate={onChange}
      styles= {[
        // ACTIVE (being drawn)
        // line stroke
        {
            "id": "gl-draw-line",
            "type": "line",
            "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
            "layout": {
              "line-cap": "round",
              "line-join": "round"
            },
            "paint": {
              "line-color": primaryColor,
              "line-dasharray": [0.2, 2],
              "line-width": 2
            }
        },
        // polygon fill
        {
          "id": "gl-draw-polygon-fill",
          "type": "fill",
          "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
          "paint": {
            "fill-color": primaryColor,
            "fill-outline-color": primaryColor,
            "fill-opacity": 0.1
          }
        },
        // polygon mid points
        {
          'id': 'gl-draw-polygon-midpoint',
          'type': 'circle',
          'filter': ['all',
            ['==', '$type', 'Point'],
            ['==', 'meta', 'midpoint']],
          'paint': {
            'circle-radius': 3,
            'circle-color': primaryColor
          }
        },
        // polygon outline stroke
        // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
        {
          "id": "gl-draw-polygon-stroke-active",
          "type": "line",
          "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
          "layout": {
            "line-cap": "round",
            "line-join": "round"
          },
          "paint": {
            "line-color": primaryColor,
            "line-dasharray": [0.2, 2],
            "line-width": 2
          }
        },
        // vertex point halos
        {
          "id": "gl-draw-polygon-and-line-vertex-halo-active",
          "type": "circle",
          "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
          "paint": {
            "circle-radius": 5,
            "circle-color": "#FFF"
          }
        },
        // vertex points
        {
          "id": "gl-draw-polygon-and-line-vertex-active",
          "type": "circle",
          "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
          "paint": {
            "circle-radius": 3,
            "circle-color": primaryColor,
          }
        },
    
        // INACTIVE (static, already drawn)
        // line stroke
        {
            "id": "gl-draw-line-static",
            "type": "line",
            "filter": ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
            "layout": {
              "line-cap": "round",
              "line-join": "round"
            },
            "paint": {
              "line-color": "#000",
              "line-width": 3
            }
        },
        // polygon fill
        {
          "id": "gl-draw-polygon-fill-static",
          "type": "fill",
          "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
          "paint": {
            "fill-color": "#000",
            "fill-outline-color": "#000",
            "fill-opacity": 0.1
          }
        },
        // polygon outline
        {
          "id": "gl-draw-polygon-stroke-static",
          "type": "line",
          "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
          "layout": {
            "line-cap": "round",
            "line-join": "round"
          },
          "paint": {
            "line-color": "#000",
            "line-width": 3
          }
        }
      ]}
    />
  );
};

export default DrawLayer;
