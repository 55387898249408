import colors from "../colors";
const econDataTypes = {
    income: {
        color: colors.he_gold,
        color_lt: colors.he_gold_lt,
        vars: ["ACS_MHI"],
        label: "Median Household Income",
        tableHeader: <>Median Household Income</>,
        metric: "dollar",
    },
    minority: {
        color: colors.he_green_md,
        color_lt: colors.he_green_lt,
        vars: ["minority"],
        label: "Minorities",
        tableHeader: <>Minorities</>,
        metric: "percent",
    },
    not_good_english: {
        color: colors.he_orange,
        color_lt: colors.he_orange_lt,
        vars: ["not_good_english"],
        label: 'Speak English less than "very well"',
        tableHeader: <>Speak English less than "very well"</>,
        metric: "percent",
    },
    family_poverty: {
        color: colors.he_purple_md,
        color_lt: colors.he_purple_lt,
        vars: ["family_poverty"],
        label: "Families Below Poverty Level",
        tableHeader: <>Families Below Poverty Level</>,
        metric: "percent",
    },
    median_age: {
        color: colors.he_spruce_md,
        color_lt: colors.he_spruce_lt,
        vars: ["median_age"],
        label: "Median Age",
        tableHeader: <>Median Age</>,
        metric: "decimal",
    },
};
export default econDataTypes;