import { Menu as GrommetMenu } from "grommet";
import { Menu as MenuIcon } from "grommet-icons";

const Menu = () => {

  return (
    <GrommetMenu
      plain
      margin='medium'
      dropBackground="white"
      dropAlign={{ top: "bottom", right: "right" }}
      icon={<MenuIcon tabIndex={0} />}
      items={[
        { label: "Headwaters Economics", href: "https://headwaterseconomics.org", target:'_blank' },
        { label: "About this tool", href: "https://headwaterseconomics.org/tools/usfws-indicators/tool-about/", target:'_blank' },
        { label: "See more tools", href: "https://headwaterseconomics.org/tools", target:'_blank' },
        { label: "Contact Us", href: "https://headwaterseconomics.org/about", target:'_blank' }
      ]}
      tabIndex={-1}
    />
  );
};
export default Menu;
