import { useContext } from "react";

import appContext from "../context/appContext";
import { useViewport } from "@headwaters-economics/web-shared";
import econDataTypes from "../assets/econDataTypes";
import colors from "../colors";
import { geoLayers } from "../layers";
// import ControlContainer from "../../../components/HeMapboxUrbica/components/ControlContainer";
import { ControlContainer } from "@headwaters-economics/web-shared/components/Map";
import numberFormatters from "../numberFormatters";
import find from "lodash/find";
import { Box } from "grommet";


const Legend = () => {
  const { screenSize } = useViewport();
  const {
    activeStep,
    selectedSocioEconomicType,
    breakPoint,
    searchFeature,
    visibleGeoLayer,
    selectedGeos,
    symbolizeAll
  } = useContext(appContext);

  if (screenSize === "mobile") return null;

  const EconLayer = () => {
    if (activeStep < 3) return null;

    let color_dark = econDataTypes[selectedSocioEconomicType].color;
    let color_light = econDataTypes[selectedSocioEconomicType].color_lt;
    const breakPointStr = numberFormatters[econDataTypes[selectedSocioEconomicType].metric].format(breakPoint);
    return (
      <Box>
        <strong>{econDataTypes[selectedSocioEconomicType].label}</strong>

        <Box className="subLegend">
          <Box direction="row" gap="xxsmall" align="center">
            <svg viewBox="0 0 16 16" width="16" height="16">
              <rect
                x="0.5"
                y="0.5"
                width="15"
                height="15"
                style={{
                  fill: color_light,
                  strokeWidth: 0.5,
                  stroke: colors.dark,
                }}
              />
            </svg>
            <span>&le;</span>
            <label>{breakPointStr}</label>
          </Box>
          <Box direction='row' gap="xxsmall" align="center">
            <svg viewBox="0 0 16 16" width="16" height="16">
              <rect
                x="0.5"
                y="0.5"
                width="15"
                height="15"
                style={{
                  fill: color_dark,
                  strokeWidth: 0.5,
                  stroke: colors.dark,
                }}
              />
            </svg>
            <span>&gt;</span>
            <label>{breakPointStr}</label>
          </Box>
        </Box>
      </Box>
    );
  };
  const SearchFeature = () => {
    if (screenSize === "mobile") return null;
    if (searchFeature) {
      return (
        <Box direction='row' gap="xxsmall" align="center">
          <svg viewBox="0 0 16 16" width="16" height="16">
            <rect
              x="0.5"
              y="0.5"
              width="15"
              height="15"
              style={{
                fill: colors.transparent,
                strokeWidth: 2,
                stroke: colors.dark,
              }}
            />
          </svg>
          <label>{searchFeature.label}</label>
        </Box>
      );
    }
    return null;
  };
  const VisibleGeoLayer = () => {
    if (screenSize === "mobile") return null;
    if (!visibleGeoLayer) return null;

    return (
      <Box direction='row' gap="xxsmall" align="center">
        <svg viewBox="0 0 16 16" width="16" height="16">
          <rect
            x="0.5"
            y="0.5"
            width="15"
            height="15"
            style={{
              fill: colors.transparent,
              strokeWidth: 2,
              stroke: colors.geo_default_stroke,
            }}
          />
        </svg>
        <label>{find(geoLayers, { id: visibleGeoLayer }).label}</label>
      </Box>
    );
  };
  const SelectedGeoLayer = () => {
    if (screenSize === "mobile") return null;
    if (
      !visibleGeoLayer ||
      selectedGeos.length === 0 ||
      (!symbolizeAll && activeStep === 3)
    )
      return null;
    return (
      <Box direction='row' gap="xxsmall" align="center">
        <svg viewBox="0 0 16 16" width="16" height="16">
          <rect
            x="0.5"
            y="0.5"
            width="15"
            height="15"
            style={{
              fill: colors.transparent,
              strokeWidth: 2,
              stroke: colors.geo_selected_stroke,
            }}
          />
        </svg>
        <label>Selected {find(geoLayers, { id: visibleGeoLayer }).label}</label>
      </Box>
    );
  };

  return (
    <ControlContainer position="lower-left">
      <span className="HeMapboxUrbica-legend">
        <SearchFeature />
        <VisibleGeoLayer />
        <SelectedGeoLayer />
        <EconLayer />
      </span>
    </ControlContainer>
  );
};
export default Legend;
