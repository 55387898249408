const geoLayers = [
    {
        id: "tract",
        label: "Census Tracts",
        layerName: "tract",
        label2: "tracts",
        minZoom: 6,
    },
    {
        id: "place",
        label: "Cities / Towns",
        layerName: "place",
        label2: "cities or towns",
        minZoom: 6,
    },
    {
        id: "county",
        label: "Counties",
        layerName: "county",
        label2: "counties",
        minZoom: 5,
    },
    {
        id: "tribal",
        label: "American Indian Areas",
        layerName: "tribal",
        label2: "American Indian areas",
        minZoom: 0,
    },
    {
        id: "state",
        label: "States",
        layerName: "state",
        label2: "states",
        minZoom: 0,
    },
];
export { geoLayers };
