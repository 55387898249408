import React, { useReducer, useEffect } from "react";

import AppContext from "./appContext";
import some from "lodash/some";

import { useEpsData } from "@headwaters-economics/web-shared";
import econDataTypes from "../assets/econDataTypes";
// import initialStateSamples from "./initialStateSamples";

const initialState = {
  searchType: 'usfws',
  visibleGeoLayer: null,
  visibleGeos: [],
  bufferDistance: 0,
  activeStep: 1,
  mapExtent: null,
  selectedGeos: [],
  highlightedGeo: null,
  hoverLayer: null,
  popupContent: null,
  // customLayerStyles: customLayerStyles,
  isLoading: true,
  isProcessing: false,
  analysisType: "",
  stateFilter: { value: "", label: "Any State" },
  // searchData: null,
  searchFeature: null,
  benchmarkGeo: { id: "0", label: "United States" },
  benchmarkName: "United States",
  isStartScreenVisible: true,
  currentMapExtent: null,

  hoveredFeature: null,
  mapCursor_default: "default",
  mapCursor_isHovering: "default",
  searchFeatureExtent: null,
  selectedSocioEconomicType: "income",
  breakPoint: 57652,
  // fetchingData:false,
  isDownloadModalOpen: false,

  // mapRef: null,
  mapIsIdle: true,

  symbolizeAll: false,
  isSearchFocusedOnce: false,
  bufferedGeometry: null,
  geoIdsThatNeedEpsData: new Set(["0"]),
  epsData: {},
};


const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    appReducer,
    initialState
    // initialStateSamples.step_3
  );

  const { epsData: _epsData, status: _epsDataStatus } = useEpsData({
    geoIDs:
      state && state.geoIdsThatNeedEpsData
        ? [...state.geoIdsThatNeedEpsData]
        : null,
    vars: Object.keys(econDataTypes),
  });

  /// acount for the fact that the old fetchEpsData function returned proportions instead of percentages
  useEffect(() => {
    if (_epsDataStatus !== "fetched") return;
    dispatch({ type: "SET_EPS_DATA", to: _epsData });
  }, [_epsData, _epsDataStatus]);

  useEffect(
    () => {
      if (state.benchmarkGeo.id in state.epsData) {
        dispatch({
          type: "SET_BREAKPOINT",
          to:
            state.epsData[state.benchmarkGeo.id][
              state.selectedSocioEconomicType
            ].pct ||
            state.epsData[state.benchmarkGeo.id][
              state.selectedSocioEconomicType
            ].value,
        });
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.selectedSocioEconomicType]
  );

  useEffect(() => {
    dispatch({
      type: "ADD_GEO_IDS_THAT_NEED_EPS_DATA",
      newIds: state.selectedGeos.map((sg) => sg.id),
    });
  }, [state.selectedGeos]);

  useEffect(() => {
    dispatch({
      type: "ADD_GEO_IDS_THAT_NEED_EPS_DATA",
      newIds: [state.benchmarkGeo.id],
    });
  }, [state.benchmarkGeo]);

  const setSearchType = (newSearhType) => {
    dispatch({ type: 'SET_SEARCH_TYPE', to: newSearhType})
  }

  const removeGeo = (geoId) => {
    dispatch({ type: "REMOVE_GEO", id: geoId });
  }
  const setBreakpoint = (val)=>{
    dispatch({ type: "SET_BREAKPOINT", to: val })
  }

  const setAnalysisType = (newType)=>{
    dispatch({ type: "SET_ANALYSIS_TYPE", to: newType });
  }

  const setHoveredFeature = (ftr) => {
    dispatch({type: 'SET_HOVERED_FEATURE', to: ftr})
  }

  const startOver = ()=>{
    console.debug('starting over')
    dispatch({ type: "START_OVER" })
  }
  const setActiveStep = ( newStep, screenSize)=>{
    dispatch({
      type: "SET_ACTIVE_STEP",
      to: newStep,
      screenSize: screenSize
    });
  }
  const setBufferDistance = (newDist, isProcessing) => {
    dispatch({
      type: "SET_BUFFER_DISTANCE",
      to: newDist,
      isProcessing: isProcessing,
    });
  }

  const addGeoIdsThatneedEpsData = (newIds) =>{
    dispatch({
      type: "ADD_GEO_IDS_THAT_NEED_EPS_DATA",
      newIds: newIds,
    });
  }

  const setGeosIntersectingGeometry =(geosIntersectingGeometry)=>{
    dispatch({
      type: "SET_GEOS_INTERSECTING_GEOMETRY",
      to: geosIntersectingGeometry,
    });
  }

  const setGeosWithinDist = (geosIntersectingBufferedGeo,bufferedGeometry) => {
    dispatch({
      type: "SET_GEOS_WITHIN_DISTANCE",
      to: geosIntersectingBufferedGeo,
      bufferedGeometry: bufferedGeometry,
    });
  }

  const setMapExtent = (newExtent) => {
    dispatch({
      type: "SET_MAP_EXTENT",
      to: newExtent,
    });
  }

  const clearBufferedGeometry = () => {
    dispatch({ type: "CLEAR_BUFFERED_GEOMETRY" });
  }

  const setVisibleGeos = (newGeos) => {
    dispatch({
      type: "SET_VISIBLE_GEOS",
      to: newGeos,
    });
  }

  const addGeo = (geoId, geoName, analysisType=null, activeStep=null) => {
    dispatch({
      type: "ADD_GEO",
      id: geoId,
      name: geoName,
      analysisType: analysisType,
      activeStep: activeStep,
    });
  }

  const setSearchFeature = (newFtr) => {
    dispatch({ type: "SET_SEARCH_FEATURE", to: newFtr });
  }

  const setIsDownloadModalOpen = (isOpen) => {
    dispatch({ type: "SET_IS_DOWNLOAD_MODAL_OPEN", to: isOpen });
  }

  const setBenchmark = (newVal) => {
    dispatch({ type: "SET_BENCHMARK", to: newVal })
  }

  const setVisibleGeoLayer = (newVal) => {
    dispatch({ type: "SET_VISIBLE_GEO_LAYER", to: newVal });
  }

  const setEpsDataType = (newDataType) => {
    dispatch({ type: "SET_EPS_DATA_TYPE", to: newDataType});
  } 

  const setSymbolizeAll = (newVal) => {
    dispatch({ type: "SET_SYMBOLIZE_ALL", to: newVal });
  }

  const setStateFilter = (newVal) => {
    dispatch({ type: "SET_STATE_FILTER", to: newVal });
  }
  
  return (
    <AppContext.Provider
      value={{
        activeStep: state.activeStep,
        selectedGeos: state.selectedGeos,
        epsData: state.epsData,
        selectedSocioEconomicType: state.selectedSocioEconomicType,
        breakPoint: state.breakPoint,
        benchmarkGeo: state.benchmarkGeo,
        searchFeature:state.searchFeature, 
        isStartScreenVisible:state.isStartScreenVisible,
        visibleGeoLayer: state.visibleGeoLayer,
        analysisType: state.analysisType,
        bufferDistance: state.bufferDistance,
        symbolizeAll: state.symbolizeAll,
        screenSize: state.screenSize,
        isProcessing:state.isProcessing,
        visibleGeos:state.visibleGeos,
        mapExtent:state.mapExtent,
        mapCursor_default:state.mapCursor_default,
        mapCursor_isHovering:state.mapCursor_isHovering,
        isDownloadModalOpen: state.isDownloadModalOpen,
        benchmarkName: state.benchmarkName,
        stateFilter: state.stateFilter,
        isSearchFocusedOnce: state.isSearchFocusedOnce,
        hoveredFeature: state.hoveredFeature,
        searchType: state.searchType,

        removeGeo,
        setBreakpoint,
        startOver,
        setAnalysisType,
        setActiveStep,
        setBufferDistance,
        addGeoIdsThatneedEpsData,
        setGeosIntersectingGeometry,
        setGeosWithinDist,
        setMapExtent,
        clearBufferedGeometry,
        setVisibleGeos,
        addGeo,
        setSearchFeature,
        setIsDownloadModalOpen,
        setBenchmark,
        setVisibleGeoLayer,
        setEpsDataType,
        setSymbolizeAll,
        setStateFilter,
        setHoveredFeature,
        setSearchType,

        state,
        dispatch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const appReducer = (state, action) => {
  switch (action.type) {
    case "SET_IS_LOADING":
      return {
        ...state,
        isLoading: action.to,
      };
    case "SET_SEARCH_FEATURE":
      return {
        ...state,
        activeStep: 1,
        searchFeature: action.to,
        selectedGeos: [],
        searchFeatureExtent: null,
        isStartScreenVisible: false,
      };
    case "SET_MAP_EXTENT":
      return {
        ...state,
        mapExtent: action.to,
      };
    case "SET_VISIBLE_GEO_LAYER":
      return {
        ...state,
        visibleGeoLayer: action.to,
        selectedGeos:[]
      };
    case "SET_VISIBLE_GEOS":
      return {
        ...state,
        visibleGeos: action.to,
      };
    case "SET_SYMBOLIZE_ALL":
      return {
        ...state,
        symbolizeAll: action.to,
      };
    case "REMOVE_GEO":
      return {
        ...state,
        selectedGeos: state.selectedGeos.filter((geo) => geo.id !== action.id),
      };
    case "ADD_GEO":
      if (some(state.selectedGeos, { id: action.id })) return { ...state };
      return {
        ...state,
        selectedGeos: state.selectedGeos.concat(
          makeGeo(action.id, action.name, state.visibleGeoLayer)
        ),
        activeStep: action.activeStep || state.activeStep,
        analysisType: action.analysisType || state.analysisType,
      };
    case "CLEAR_GEOS":
      return {
        ...state,
        selectedGeos: [],
      };
    case "SET_ANALYSIS_TYPE":
      // update the map cursor
      let mapCursor_default = "default";
      let mapCursor_isHovering = "default";
      if (action.to === "drawPolygon") {
        mapCursor_default = "crosshair";
      } else {
        mapCursor_isHovering = "pointer";
      }
      return {
        ...state,
        analysisType: action.to,
        // hoverLayer: action.to === "drawPolygon" ? null : state.visibleGeoLayer,
        selectedGeos: action.to ? state.selectedGeos : [],
        bufferDistance: 0,
        mapCursor_default: mapCursor_default,
        mapCursor_isHovering: mapCursor_isHovering,
      };
    case "SET_STATE_FILTER":
      return {
        ...state,
        stateFilter: action.to,
      };
    case "SET_IS_SEARCH_FOCUSED_ONCE":
      return {
        ...state,
        isSearchFocusedOnce: action.to,
      };

    case "SET_ACTIVE_STEP":
      if (state.activeStep === action.to) return state;
      let newState = {
        activeStep: action.to,
        analysisType: "",
        mapCursor_default: "default",
        mapCursor_isHovering: "default",
      };
      switch (action.to) {
        case 1:
          newState.selectedGeos = [];
          break;

        case 2:
          newState.analysisType =
            action.screenSize === "mobile" ? "mapClick" : "";
          newState.hoverLayer = state.visibleGeoLayer;
          break;
        case 3:
          newState.hoverLayer = state.visibleGeoLayer;
          newState.mapCursor_isHovering = "pointer";
          break;
        default:
          break;
      }

      return {
        ...state,
        ...newState,
      };
    case "START_OVER":
      return {
        ...state,
        activeStep: 1,
        visibleGeoLayer: null,
        selectedGeos: [],
        mapCursor_default: "default",
        mapCursor_isHovering: "default",
        isSearchFocusedOnce: false,
        isStartScreenVisible: true
      };
    case "SET_BUFFER_DISTANCE":
      return {
        ...state,
        bufferDistance: action.to,
        isProcessing: action.isProcessing,
      };
    case "SET_GEOS_WITHIN_DISTANCE":
      return {
        ...state,
        selectedGeos: action.to.map((geo) =>
          makeGeo(geo.id, geo.name, state.visibleGeoLayer)
        ),
        isProcessing: false,
        bufferedGeometry: action.bufferedGeometry,
      };
    case "CLEAR_BUFFERED_GEOMETRY":
      return {
        ...state,
        bufferedGeometry: null,
      };
    case "SET_GEOS_INTERSECTING_GEOMETRY":
      return {
        ...state,
        selectedGeos: action.to.map((geo) =>
          makeGeo(geo.id, geo.name, state.visibleGeoLayer)
        ),
        isProcessing: false,
      };
    case "SET_EPS_DATA":
      return {
        ...state,
        epsData: action.to,
      };
    case "SET_EPS_DATA_TYPE":
      return {
        ...state,
        selectedSocioEconomicType: action.to,
      };
    case "SET_BREAKPOINT":
      return {
        ...state,
        breakPoint: action.to,
      };
    case "ADD_GEO_IDS_THAT_NEED_EPS_DATA":
      return {
        ...state,
        geoIdsThatNeedEpsData: new Set([
          ...state.geoIdsThatNeedEpsData,
          ...action.newIds,
        ]),
      };
    case "SET_IS_DOWNLOAD_MODAL_OPEN":
      return {
        ...state,
        isDownloadModalOpen: action.to,
      };
    case "SET_BENCHMARK":
      return {
        ...state,
        benchmarkGeo: action.to,
      };
    case "SET_HOVERED_FEATURE":
      return {
        ...state,
        hoveredFeature: action.to
      }
    case "SET_SEARCH_TYPE":
      return {
        ...state,
        searchType: action.to
      }
    default:
      throw new Error();
  }
};

export default AppProvider;

const makeGeo = (id, name, geoLevel) => {
  return {
    id: id,
    label: name,
    name: name,
    geoLevel: geoLevel,
    geo_level: geoLevel,
  };
};
