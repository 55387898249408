import { useContext } from "react";

import appContext from "../context/appContext";
import { useViewport } from "@headwaters-economics/web-shared";
import coverPhoto from "../assets/USFWS_bison.jpg";
import Search from "../components/Search";
import usfwsLogo from "../assets/USFWS_logo_color.svg";
import { Box, Image, Text } from "grommet";

const IntroText = () => {
  return (
    <Box direction="column">
      <Text color="light-1" size="xxlarge" weight="bold">
        {" "}
        Get socioeconomic profiles.
      </Text>
      <Text color="light-1">
        Download reports for places near U.S. Fish &amp; Wildlife Service
        lands.
      </Text>
    </Box>
  );
};


const StartScreen = () => {
  const { screenSize } = useViewport();
  const { isStartScreenVisible } = useContext(appContext);

  if (!isStartScreenVisible) return null;

  return (
    <Box fill background={{ size: "cover", image: "url(" + coverPhoto + ")" }}>
      <Box align="center">
        <Box
          width={"xlarge"}
          pad={{ top: "xlarge", horizontal: "small" }}
          gap="small"
        >
          <Box /* Image and Intro Text*/>
            <Box direction={screenSize !== "mobile" ? "row" : "column"}>
              <Box
                align="center"
                justify="center"
                height="xsmall"
                width="xsmall"
              >
                <Image alignSelf="center" fill src={usfwsLogo} />
              </Box>
              <IntroText />
            </Box>
          </Box>
          <Search />
        </Box>
      </Box>
    </Box>
  );
};
export default StartScreen;
