import React from "react";
import { useContext } from "react";

import appContext from "./context/appContext";
// import Layout from "./components/Layout";
import { Grommet, Box } from "grommet";
import { deepMerge } from "grommet/utils";
import GoogleAnalytics from "@headwaters-economics/web-shared/components/GoogleAnalytics";
import { HeMainTheme, ViewportProvider, useViewport } from "@headwaters-economics/web-shared";
import StartScreen from "./screens/StartScreen";
import AppProvider from "./context/AppProvider";
import PrimaryScreen from "./screens/PrimaryScreen";

const usfwsTheme = deepMerge(HeMainTheme, {
  global: {
    spacing: "10px",
    focus: {
      radius: "0",
      size: "1px",
      border: {
        radius: "0",
      },
      shadow: {
        color: "none",
        radius: "0",
      },
    },
    font: {
      family: "Source Sans Pro, sans-serif",
    },
  },
  anchor: {
    color: {
      dark: "light-2",
      light: "dark-2",
    },
  },
  select: {
    step: 10,
    icons: {
      color: "dark-1",
    },
    background: "#ffffff",
  },
  // textInput: {
  //   size: "1.5rem",
  // },
  tabs: {
    gap: ".5em",
  },
  tab: {
    hover: {
      background: "light-2",
    },
    active: {
      weight: 700,
    },
    border: { size: "none" },
    pad: "xsmall",
    margin: "none",
  },
  rangeInput: {
    track: {
      height: "5px",
      // color: 'accent-1',
      lower: {
        color: "rgb(150,150,150)",
        opacity: 0.2,
      },
      upper: {
        color: "rgb(61,113,153)",
        opacity: 0.5,
      },
    },
    thumb: {
      color: "rgb(61,113,153)",
    },
  },
  rangeSelector: {
    edge: {
      type: "bar",
    },
  },
  accordion: {
    background: "dark-2",
    icons: {
      color: {
        dark: "#f2f2f2",
        light: "text",
      },
    },
    border: {
      color: {
        dark: "dark-2",
        light: "text",
      },
    },
  },
  button: {
    default: {
      background: {
        color: "heDarkBlue",
      },
      border: {
        radius: "5px",
      },
    },
    disabled: {
      background: {
        color: "dark-1",
      },
    },
    border: {
      color: "dark-1",
      width: "2px",
      radius: "5px",
    },
  },
});

const App = () => {
  return (
    <AppProvider>
      <ViewportProvider>
        <Layout />
      </ViewportProvider>
    </AppProvider>
  );
};

const Layout = () => {
  const { screenSize } = useViewport();
  const { isStartScreenVisible } = useContext(appContext);
  return (
    <Grommet full={screenSize!=='mobile' || isStartScreenVisible} theme={usfwsTheme} className="app">
      <GoogleAnalytics gaAccount={"headwaterseconomics"} />
      <Box fill={screenSize!=='mobile' || isStartScreenVisible}>
        <StartScreen />
        <PrimaryScreen />
      </Box>
    </Grommet>
  );
};

export default App;
