import { useContext } from "react";

import appContext from "../../context/appContext";
import { useViewport } from "@headwaters-economics/web-shared";

import find from "lodash/find";

// import NumberedStep3Mobile from "./Step_3_mobile";
import econDataTypes from "../../assets/econDataTypes";
import { geoLayers } from "../../layers";
import { Box, CheckBox, Text } from "grommet";
import { Download } from "grommet-icons";
import {
  ActionButton,
  StartOverButton,
  WideRadioButtonGroup,
} from "../Buttons";

const NumberedStep3 = () => {
  const { screenSize } = useViewport();
  const {
    selectedGeos,
    activeStep,
    symbolizeAll,
    visibleGeoLayer,
    selectedSocioEconomicType,
    setSymbolizeAll,
    setEpsDataType,
    setIsDownloadModalOpen,
    startOver,

  } = useContext(appContext);

  if (selectedGeos.length === 0) return null;
  if (activeStep !== 3) return null;

  // if (activeStep === 3 && screenSize === "mobile")
  //   return <NumberedStep3Mobile />;

  const getButtonLabel = (label, color1, color2) => {
    // let colorObj = colorHelper(color);
    return (
      <Box direction="row">
        <svg
          viewBox="0 0 100 100"
          width="20"
          height="16px"
          style={{ paddingRight: 10 }}
        >
          <rect x="0" y="0" width="50" height="100" style={{ fill: color2 }} />
          <rect
            x="50"
            y="0"
            width="500"
            height="100"
            style={{ fill: color1 }}
          />
        </svg>
        <Text size="small">{econDataTypes[label].label}</Text>
      </Box>
    );
  };
  const selectOptions = [
    "income",
    "minority",
    "not_good_english",
    "family_poverty",
    "median_age",
  ].map((dataType) => {
    return {
      label:
        screenSize === "mobile"
          ? dataType
          : getButtonLabel(
              dataType,
              econDataTypes[dataType].color,
              econDataTypes[dataType].color_lt
            ),
      value: dataType,
    };
  });

  return (
    <Box gap="xsmall">
      <CheckBox
        pad="xsmall"
        checked={symbolizeAll}
        label={
          <Text alignSelf="end" size="small">
            Map all {find(geoLayers, { id: visibleGeoLayer }).label2}
          </Text>
        }
        onChange={() => {
          setSymbolizeAll(!symbolizeAll);
        }}
      />

      <WideRadioButtonGroup
        name="StepThreeButtonGroup"
        options={selectOptions}
        onChange={(evt) => {
          const newVal = evt.target.value;
          setEpsDataType(newVal);
        }}
        value={selectedSocioEconomicType}
      />

      <ActionButton
        onClick={() => {
          setIsDownloadModalOpen(true);
        }}
        label="DOWNLOAD FULL REPORT"
        icon={<Download />}
      />
      <StartOverButton onClick={startOver} />
    </Box>
  );
};
export default NumberedStep3;
