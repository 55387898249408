import { useContext, useEffect } from "react";
import HeMapboxComponent, {
  ZoomInWarning,
  Popup,
  getBufferedExtent,
  getMergedExtent,
  extentsEqual,
} from "@headwaters-economics/web-shared/components/Map";
// import Draw from "@urbica/react-map-gl-draw";
import MapPopupContent from "./PopupContent";
import Legend from "../Legend";

import appContext from "../../context/appContext";
import { useViewport, useExtentOfGeos } from "@headwaters-economics/web-shared";
import UsfwsLayers from "./USFWSLayers";
import CensusLayers from "./CensusLayers";
import DrawLayer from "./DrawLayer";

import { Box } from "grommet";
import BufferToolLayer from "./BufferToolLayer";

const Map = () => {
  const { screenSize /*, appHeight, appWidth */ } = useViewport();
  const {
    searchFeature,
    analysisType,
    visibleGeoLayer,
    visibleGeos,
    symbolizeAll,
    activeStep,
    mapExtent,
    mapCursor_default,
    mapCursor_isHovering,
    addGeoIdsThatneedEpsData,
    setMapExtent,
    setVisibleGeos,
    hoveredFeature,
    selectedGeos,
  } = useContext(appContext);

  const { extentOfGeos: searchFeatureExtent } = useExtentOfGeos({
    geoIDs: searchFeature ? [searchFeature.id] : null,
    table: 'he.geos_usfws'
  });

  useEffect(() => {
    if (hoveredFeature) {
      addGeoIdsThatneedEpsData([hoveredFeature.id]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoveredFeature]);

  useEffect(
    () => {
      if (searchFeatureExtent)
        setMapExtent(getBufferedExtent(searchFeatureExtent, 4));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchFeatureExtent]
  );

  useEffect(
    () => {
      if (symbolizeAll) {
        addGeoIdsThatneedEpsData(visibleGeos);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleGeos, symbolizeAll]
  );

  const onMapIdle = (mr) => {
    if (activeStep < 3) return;
    const visibleGeos = mr.target.queryRenderedFeatures( 
      {
      layers: [visibleGeoLayer + "_default_"+visibleGeoLayer+"-1"],
    });

    setVisibleGeos(visibleGeos.map((vg) => vg.id.toString()));
  };

  if (!mapExtent) return null;

  return (
    <Box fill>
      <HeMapboxComponent
        mapStyle="mapbox://styles/headmin/ckm4h3rhz9u2z17o0ytb2i8w3"
        cursor_default={mapCursor_default}
        cursor_isHovering={mapCursor_isHovering}
        extent={mapExtent}
        extentPadding={100}
        screenSize={screenSize}
        onIdle={onMapIdle}
        zoomGeoIds={selectedGeos.map((sg) => sg.id)}
        cursor={activeStep === 2 && analysisType === "drawPolygon" ? 'crosshair' : 'auto'}
        cooperativeGestures={true}
      >
        <ZoomInWarning activeMapLayer={visibleGeoLayer} />
        <Legend />

        <Popup closeButton={false} closeOnClick={false} maxWidth="600px">
          {hoveredFeature && <MapPopupContent />}
        </Popup>
        <ZoomToSelection />
        <UsfwsLayers />
        {activeStep === 2 && analysisType === "drawPolygon" && <DrawLayer />}
        {activeStep === 2 && analysisType === "withinDist" && (
          <BufferToolLayer />
        )}
        <CensusLayers />
      </HeMapboxComponent>
    </Box>
  );
};

const ZoomToSelection = () => {
  const { mapExtent, setMapExtent, selectedGeos } = useContext(appContext);
  const { extentOfGeos: extentOfZoomGeoIds } = useExtentOfGeos({
    geoIDs: selectedGeos.map((sg) => sg.id),
  });
  useEffect(() => {
    if (!extentOfZoomGeoIds) return;
    const newExtent = mapExtent
      ? getMergedExtent([mapExtent, extentOfZoomGeoIds])
      : extentOfZoomGeoIds;
    if (!extentsEqual(mapExtent, newExtent)) setMapExtent(extentOfZoomGeoIds);
  }, [extentOfZoomGeoIds, mapExtent, setMapExtent]);

  return null;
};

export default Map;
