const numberFormatters = {
  dollar: new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  }),
  percent: new Intl.NumberFormat("en-US", {
    style: "percent",
    maximumFractionDigits: 1,
  }),
  decimal: new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 2,
  }),
  compact: {
    dollar: new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      notation: "compact",
    }),
    decimal: new Intl.NumberFormat("en-US", {
      style: "decimal",
      notation: "compact",
    }),
    percent: new Intl.NumberFormat("en-US", {
      style: "percent",
      maximumFractionDigits: 1,
    }),
  },
};


export default numberFormatters;
