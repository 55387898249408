import { useViewport } from "@headwaters-economics/web-shared";
import { Box, Header, Image, Text } from "grommet";
import logo from "../assets/USFWS_logo_color.svg";
import Menu from "./Menu";

const AppHeader = ({...props}) => {
  const { screenSize } = useViewport();
  return (
    <Header
      direction="row"
      align="center"
      justify="between"
      background="dark-2"
      {...props}
    >
      <Box direction="row" gap="small" pad="xsmall" >
        <Image src={logo} height={"50px"} />
        <Box justify="center">
          <Text size={screenSize === "mobile" ? "small" : "large"}>
            U.S. Fish & Wildlife Service Socioeconomic Profiles
          </Text>
        </Box>
      </Box>
      <Menu />
    </Header>
  );
};

export default AppHeader;
