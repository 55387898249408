import { useContext, useState } from "react";

import appContext from "../context/appContext";
import { FormClose, Revert } from "grommet-icons";

// import ReactTooltip from "react-tooltip";
import Search from "./Search";
import { Box, Button, DropButton, Text } from "grommet";

const AreaOfInterest = () => {
  const { searchFeature, isStartScreenVisible } =
    useContext(appContext);
  const [isChangeLocDialogOpen, set_isChangeLocDialogOpen] = useState(false);
  if (!searchFeature || isStartScreenVisible) return null;

  return (
    <Box pad="small">
      <Text size="xlarge" weight={"bolder"}>{searchFeature.label}</Text>
      <Box border={{side:"all",size:"xsmall", color:"light-4"}} pad={{vertical:"xsmall"}} margin={{top:"xsmall"}} hoverIndicator={{background:{color:"dark-2"}}}>
        <DropButton
          plain
          label={<Text weight="bolder" size="small">Change Location</Text>}
          open={isChangeLocDialogOpen}
          onOpen={() => set_isChangeLocDialogOpen(true)}
          onClose={() => set_isChangeLocDialogOpen(false)}
          dropContent={
            <Box>
              <Box background={"dark-1"} pad={{horizontal:"xsmall"}} direction="row" justify="between" align={"center"}>
                <Text weight={"bold"} size="small">Change Location</Text>
                <Button plain icon={<FormClose />} onClick={()=>{set_isChangeLocDialogOpen(false)}} />
              </Box>
              <Box pad="xsmall">
                <Search onSelect={() => set_isChangeLocDialogOpen(false)} />
              </Box>
            </Box>
          }
          dropProps={{
            background: "dark-2",
            elevation: "medium",
            round: "xsmall",
          }}
          icon={<Revert size="small" />}
        />
      </Box>

    </Box>
  );
};
export default AreaOfInterest;
