import { useCallback, useContext } from "react";

import appContext from "../context/appContext";

import { Box, Button, Grid, Text } from "grommet";
import { CaretUpFill } from "grommet-icons";

const SearchTypeToggleButton = () => {
  const { searchType, setSearchType } = useContext(appContext);

  const SearchTypeButton = useCallback(
    ({ type, label }) => {
      return (
        <Box>
          <Button
            plain
            onClick={() => {
              setSearchType(type);
            }}
          >
            <Box
              pad="xsmall"
              background={
                type === searchType
                  ? "rgba(41,129,180,.8)"
                  : "hsla(0,0%,55%,.7)"
              }
              align="center"
              color="white"
              border={{ color: "white" }}
            >
              <Text size="small" color="white">
                {label}
              </Text>
            </Box>
          </Button>
          {type === searchType && (
            <Box alignSelf="center" margin={{ top: "-18px", bottom: "-30px" }}>
              <CaretUpFill size="48px" color="white" style={{ zIndex: 1 }} />
            </Box>
          )}
        </Box>
      );
    },
    [searchType, setSearchType]
  );

  return (
    <Grid columns={["50%", "50%"]} fill="horizontal">
      <SearchTypeButton
        type="usfws"
        label={"USFWS unit"}
        searchType={searchType}
      />
      <SearchTypeButton
        type="place"
        label={"City or town"}
        searchType={searchType}
      />
    </Grid>
  );
};

export default SearchTypeToggleButton;
