import { useContext, useState, useEffect, useRef } from "react";
import { CensusLayer } from "@headwaters-economics/web-shared/components/Map";
import colors from "../../colors";

import appContext from "../../context/appContext";
import { useViewport } from "@headwaters-economics/web-shared";

import econDataTypes from "../../assets/econDataTypes";
import {geoLayers} from "../../layers";

import some from "lodash/some";

const CensusLayers = () => {
  const { screenSize /*, appHeight, appWidth */ } = useViewport();
  const {
    selectedGeos,
    analysisType,
    visibleGeoLayer,
    visibleGeos,
    symbolizeAll,
    activeStep,

    epsData,
    selectedSocioEconomicType,
    breakPoint,
    removeGeo,
    addGeoIdsThatneedEpsData,

    addGeo,
    setHoveredFeature,
  } = useContext(appContext);

  const [selectedGeoIds, set_selectedGeoIds] = useState([]);

  const selectedGeosRef = useRef(null);
  selectedGeosRef.current = selectedGeos;
  const analysisTypeRef = useRef(null);
  analysisTypeRef.current = analysisType;
  const activeStepRef = useRef(null);
  activeStepRef.current = activeStep;

  useEffect(() => {
    set_selectedGeoIds(selectedGeos.map((sg) => sg.id));
  }, [selectedGeos]);

  useEffect(
    () => {
      if (symbolizeAll) {
        addGeoIdsThatneedEpsData(visibleGeos);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleGeos, symbolizeAll]
  );

  useEffect(() => {
    setHoveredFeature(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleGeoLayer]);

  if (!visibleGeoLayer) return null;

  return (
    <>
      {geoLayers.map((gl) => {
        if (gl.layerName !== visibleGeoLayer) return null;
        return (
          <CensusLayer
            layerName={visibleGeoLayer}
            beforeId="housenum-label"
            color_default_border={colors.geo_default_stroke}
            color_default_fill={
              activeStep < 3 ? colors.geo_default_fill : "transparent"
            }
            color_hover_border={colors.geo_highlight_stroke}
            color_hover_fill={
              activeStep < 3 ? colors.geo_highlight_fill : "transparent"
            }
            color_select_border={colors.geo_selected_stroke}
            color_select_fill={
              activeStep < 3 ? colors.geo_selected_fill : "transparent"

              // econDataTypes[selectedSocioEconomicType].color_lt
            }
            disabled={analysisType === "drawPolygon"}
            onClick={(ftr) => {
              const ftrId = ftr.id;
              if (activeStepRef.current === 3 && screenSize === "mobile")
                return;
              if (
                activeStepRef.current === 1 ||
                (activeStepRef.current === 2 && analysisTypeRef.current === "")
              ) {
                addGeo(ftrId, ftr.properties.name, "mapClick", 2);
              } else {
                if (some(selectedGeosRef.current, { id: ftrId })) {
                  removeGeo(ftrId);
                } else {
                  addGeo(ftrId, ftr.properties.name);
                }
              }
            }}
            onHover={(ftr) => {
              setHoveredFeature(ftr);
            }}
            selectedFeatures={selectedGeos.map((sg) => ({
              id: sg.id,
              geo_level: sg.geoLevel,
            }))}
            color_label_halo="white"
            lineWidth_selectLayer={symbolizeAll ? 2 : 1}
            uniqId={visibleGeoLayer + "-1"}
            key={visibleGeoLayer + "-1"}
          />
        );
      })}
      {geoLayers.map((gl) => {
        if (gl.layerName !== visibleGeoLayer || activeStep !== 3) return null;
        return (
          <CensusLayer
            layerName={visibleGeoLayer}
            disabled
            beforeId="housenum-label"
            color_default_border={colors.geo_default_stroke}
            color_default_fill={[
              "case",
              [
                "any",
                ...(symbolizeAll ? visibleGeos : selectedGeoIds)
                  .filter(
                    (id) => !(id in epsData) || epsData[id] === "fetching"
                  )
                  .map((id) => ["==", ["id"], parseInt(id, 10)]),
              ],
              "transparent",
              [
                "any",
                ...(symbolizeAll ? visibleGeos : selectedGeoIds)
                  .filter((id) => id in epsData)
                  .filter((id) => epsData[id] !== "fetching")
                  .filter((id) => {
                    return (
                      (epsData[id][selectedSocioEconomicType].pct ||
                        epsData[id][selectedSocioEconomicType].value) <
                      breakPoint
                    );
                  })
                  .map((id) => ["==", ["id"], parseInt(id, 10)]),
              ],
              econDataTypes[selectedSocioEconomicType].color_lt,
              econDataTypes[selectedSocioEconomicType].color,
            ]}
            filterIds={symbolizeAll ? visibleGeos : selectedGeoIds}
            uniqId={visibleGeoLayer + "-2"}
            key={visibleGeoLayer + "-2"}
          />
        );
      })}
    </>
  );
};

export default CensusLayers;
