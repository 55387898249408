import { useContext, useState, useEffect } from "react";

import appContext from "../../context/appContext";
import { useViewport } from "@headwaters-economics/web-shared";

import find from "lodash/find";
import { geoLayers } from "../../layers";
import GeoList from "@headwaters-economics/web-shared/components/GeoList";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDrawPolygon,
  faHandPointer,
  faExpandArrowsAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Box, Grid, Text, Paragraph, RangeInput } from "grommet";
import { FormPreviousLink } from "grommet-icons";
import {
  WideRadioButtonGroup,
  ActionButton,
  StartOverButton,
} from "../Buttons";
// import Slider from "react-input-range";

const NumberedStep2_Content = () => {
  const { screenSize } = useViewport();

  const {
    selectedGeos,
    visibleGeoLayer,
    analysisType,
    bufferDistance,
    searchFeature,
    removeGeo,
    setAnalysisType,
    startOver,
    setActiveStep,
    setBufferDistance,
  } = useContext(appContext);

  const clickOrTap = screenSize === "mobile" ? "Tap" : "Click";

  const MaxGeosWarning = () => {
    if (selectedGeos.length <= 50) return null;
    return (
      <Text color="heRed" size="small">
        Reduce your list to fewer than 50 places to continue.
      </Text>
    );
  };

  const AnalysisTypeSelector = () => {
    return (
      <Box>
        <Text>
          <strong>Add</strong> {find(geoLayers, { id: visibleGeoLayer }).label2}
          .
        </Text>

        <WideRadioButtonGroup
          name="StepTwoButtonGroup"
          options={[
            {
              label: (
                <Box direction="row">
                  <FontAwesomeIcon icon={faHandPointer} />
                  &nbsp;&nbsp;Click on map
                </Box>
              ),
              value: "mapClick",
            },
            {
              label: (
                <Box direction="row">
                  <FontAwesomeIcon icon={faDrawPolygon} />
                  &nbsp;&nbsp;Draw polygon
                </Box>
              ),
              value: "drawPolygon",
            },
            {
              label: (
                <Box direction="row">
                  <FontAwesomeIcon icon={faExpandArrowsAlt} />
                  &nbsp;&nbsp;Within a distance
                </Box>
              ),
              value: "withinDist",
            },
          ]}
          onChange={(evt) => {
            const newVal = evt.target.value;
            setAnalysisType(newVal);
          }}
          value={visibleGeoLayer}
        />
        <Grid columns={["auto", "1fr"]}>
          <StartOverButton onClick={startOver} />
        </Grid>
      </Box>
    );
  };
  const WithinDist = () => {
    return (
      <Box>
        <Paragraph>
          <strong>Select</strong>{" "}
          {find(geoLayers, { id: visibleGeoLayer }).label2} within{" "}
          {bufferDistance} miles of {searchFeature.label}.
        </Paragraph>

        <Box direction="row">
          <Text>0</Text>
          <Box pad={"small"} width="small" fill>
            <BufferSlider
              bufferDistance={bufferDistance}
              setBufferDistance={setBufferDistance}
            />
          </Box>
          <Text>50</Text>
        </Box>

        <div style={{ paddingBottom: 20 }} />
        <AnalysisFooter />
      </Box>
    );
  };

  const MapClick = () => {
    return (
      <>
        <p>
          <strong>{clickOrTap}</strong> on the map to add/remove{" "}
          {find(geoLayers, { id: visibleGeoLayer }).label2}.
        </p>
        <AnalysisFooter />
      </>
    );
  };

  const DrawPolygon = () => {
    return (
      <>
        <p>
          <strong>Draw</strong> the area of interest to select intersecting{" "}
          {find(geoLayers, { id: visibleGeoLayer }).label2}.
        </p>
        <p>
          <strong>{clickOrTap}</strong> the map to begin drawing. Double-click
          to finish.
        </p>
        <AnalysisFooter />
      </>
    );
  };

  const AnalysisFooter = () => {
    if (screenSize === "mobile") {
      return (
        <Text>
          <strong>{selectedGeos.length}</strong>{" "}
          {find(geoLayers, { id: visibleGeoLayer }).label} selected
        </Text>
      );
    }
    return (
      <Box>
        <Text size="small">
          <MaxGeosWarning />
          <Box direction="row">
            <strong>{selectedGeos.length}</strong>
            &nbsp;{find(geoLayers, { id: visibleGeoLayer }).label} selected
          </Box>

          {/* <div style={{ paddingBottom: 20 }}> */}
          <Box>
            <GeoList
              selectedGeos={selectedGeos}
              dataAlerts={[]}
              removeGeo={removeGeo}
            />
          </Box>
          <Grid columns={["auto", "1fr", "auto"]} pad={{ vertical: "small" }}>
            <ActionButton
              onClick={() => {
                setAnalysisType("");
              }}
              label="BACK"
              icon={<FormPreviousLink />}
            />
            <Box></Box>
            <ActionButton
              onClick={() => {
                setActiveStep(3, screenSize);
              }}
              disabled={selectedGeos.length === 0 || selectedGeos.length > 50}
              label="CONTINUE"
            />
          </Grid>
        </Text>
      </Box>
    );
  };

  return (
    <Text size="small">
      {analysisType === "" && <AnalysisTypeSelector />}
      {analysisType === "withinDist" && <WithinDist />}
      {analysisType === "mapClick" && <MapClick />}
      {analysisType === "drawPolygon" && <DrawPolygon />}
    </Text>
  );
};
export default NumberedStep2_Content;

const BufferSlider = ({ bufferDistance, setBufferDistance }) => {
  const [bufferSliderVal, set_bufferSliderVal] = useState(bufferDistance);

  useEffect(() => {
    // debounce breakpoint setter
    const t = setTimeout(() => {
      setBufferDistance(bufferSliderVal);
    }, 500);

    return () => {
      clearTimeout(t);
    };
    // eslint-disable-next-line
  }, [bufferSliderVal]);

  return (
    <RangeInput
      min={0}
      max={50}
      value={bufferSliderVal}
      onChange={(evt) => set_bufferSliderVal(evt.target.value)}
    />
  );
};
