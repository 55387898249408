import { useContext } from "react";

import { useViewport } from "@headwaters-economics/web-shared";
import appContext from "../context/appContext";
import LocationPicker from "@headwaters-economics/web-shared/components/LocationPicker";
import StateFilter from "./StateFilter";

import { Grid } from "grommet";
import SearchTypeToggleButton from "./SearchTypeToggle";

const Input = ({ onSelect, ...props }) => {
  const { stateFilter, setSearchFeature, searchType } = useContext(appContext);
  return (
    <LocationPicker
      {...props}
      geoTypes={searchType === "usfws" ? ["usfws"] : ["he-place"]}
      geoFilterId={stateFilter.value}
      placeholder={
        "Which " +
        (searchType === "usfws" ? "USFWS unit" : "city or town") +
        (stateFilter.value === "" ? "?" : " in " + stateFilter.label + "?")
      }
      onSelection={(item) => {
        if (onSelect) onSelect();
        setSearchFeature(item);
      }}
      width="auto"
      table={searchType === "usfws" ? "he.geos_usfws" : null}
    />
  );
};

const Search = ({ onSelect }) => {
  const { screenSize } = useViewport();
  return (
    <Grid
      areas={[
        ["searchTypeToggle", "empty"],
        ["searchInput", "stateSelector"],
      ]}
      columns={["auto", screenSize === 'mobile' ? '0px' : "small"]}
      rows={["auto", "auto"]}
      gap="small"
    >
      <SearchTypeToggleButton gridArea="searchTypeToggle" />
      <Input onSelect={onSelect} round="xsmall" gridArea="searchInput" />
      {screenSize !== 'mobile' && <StateFilter round="xsmall" gridArea="stateSelector" />}
    </Grid>
  );
};

export default Search;
