import { Box, Grid } from "grommet";
import { useContext } from "react";
import { useViewport } from "@headwaters-economics/web-shared";
import appContext from "../context/appContext";
import Map from "./Map/Map";
import GrommetChart from "./GrommetChart";

const Main = () => {
  const { screenSize } = useViewport();
  const {
    activeStep,
  } = useContext(appContext);

  const tableVisible = screenSize !== "mobile" && activeStep === 3;

  return (
    <Box elevation="medium" fill>
      {tableVisible ? (
        <Grid rows={["flex", "auto"]} fill>
          <Map />
          <Box background={"white"}>
            <GrommetChart />
          </Box>
        </Grid>
      ) : (
        <Map />
      )}
    </Box>
  );
};
export default Main;

