import Step1 from "./Step_1";
import Step2 from "./Step_2";
import Step3 from "./Step_3";

import { useContext } from "react";
import appContext from "../../context/appContext";
import { geoLayers } from "../../layers";

import AreaOfInterest from "../AreaOfInterest";
import {
  Accordion,
  AccordionPanel,
  Box,
  Grid,
  Text,
  ThemeContext,
} from "grommet";
import styled from "styled-components";

const customAccordionTheme = {
  accordion: {
    border: false,
    panel: {
      border: {
        color: "light-4",
        side: "all"
      },
    },
  },
};

const StyledAccordion = styled(Accordion)`
  > div:nth-child(odd) {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }
`;

const LeftPane = () => {
  const { activeStep, setActiveStep, visibleGeoLayer, selectedGeos } =
    useContext(appContext);


  const AccordionPanelHeader = ({ number, label, onHeaderClick }) => {
    return (
      <Box direction="row" onClick={() => setActiveStep(number)} pad={{horizontal:"xsmall"}}>
        <Box>
          <Text
            size="3xl"
            weight="bold"
            color={number === activeStep ? "brand" : "lightgrey"}
          >
            {number}
          </Text>
        </Box>

        <Box alignSelf="center" pad={{ left: "small" }}>
          <Text weight={"lighter"}>{label}</Text>
        </Box>
      </Box>
    );
  };

  const AccordionPanelContent = ({ children }) => {
    return <Box pad={{horizontal:"small", top:"xsmall", bottom:"medium"}}>{children}</Box>;
  };

  const isStep2Visible = visibleGeoLayer?true:false;
  const isStep3Visible = selectedGeos && selectedGeos.length > 0;

  return (
    <Grid rows={["auto", "flex"]} fill>
      <Box>
        <AreaOfInterest />
      </Box>
      <Box overflow={{ vertical: "auto" }} pad="small">
        <ThemeContext.Extend value={customAccordionTheme}>
          <StyledAccordion activeIndex={activeStep - 1} gap="medium">
            <AccordionPanel
              style={{borderRadius:"3px"}}
              header={
                <AccordionPanelHeader number={1} label="View Boundaries" />
              }
            >
              {activeStep === 1 && (
                <AccordionPanelContent>
                  <Step1 />
                </AccordionPanelContent>
              )}
            </AccordionPanel>
            {isStep2Visible && (
              <AccordionPanel
                header={
                  <AccordionPanelHeader number={2} label={"Select "+geoLayers.find(gl=>gl.id === visibleGeoLayer).label} />
                }
              >
                {activeStep === 2 && (
                  <AccordionPanelContent>
                    <Step2 />
                  </AccordionPanelContent>
                )}
              </AccordionPanel>
            )}
            {isStep3Visible && (
              <AccordionPanel
                header={
                  <AccordionPanelHeader number={3} label="Explore Results" />
                }
              >
                {activeStep === 3 && (
                  <AccordionPanelContent>
                    <Step3 />
                  </AccordionPanelContent>
                )}
              </AccordionPanel>
            )}
          </StyledAccordion>
        </ThemeContext.Extend>
      </Box>
    </Grid>
  );
};

export default LeftPane;
