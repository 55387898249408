const colors = {
    transparent: "rgba(0,0,0,0)",
    dark: "rgba(0, 0, 0, 1)",    
    textHalo:"hsl(0, 0%, 100%)",
    geo_default_stroke: 'rgba(143, 43, 0, 0.2)',
    geo_default_fill: "rgba(143, 43, 0, 0.05)",
    geo_selected_fill: "rgba(143, 43, 0, 0.2)",
    geo_selected_stroke: 'rgba(143, 43, 0, 1)',
    geo_highlight_stroke:"rgba(100, 100, 100,1)",
    geo_highlight_fill:"rgba(143, 43, 0, 0.4)",
    usfws_default_stroke:"rgba(52, 183, 170, 1)",
    usfws_default_fill: "rgba(64, 224, 208, 0.25)",
    buffer: 'rgb(255,0,0)',
    he_gold: 'rgb(234, 170, 23)',
    he_gold_md: 'rgb(239, 191, 81)',
    he_gold_lt: 'rgb(244, 212, 139)',
    he_orange: 'rgb(195, 109, 39)',
    he_orange_md: 'rgb(210, 146, 93)',
    he_orange_lt: 'rgb(225, 182, 147)',
    he_green: 'rgb(96, 107, 21)',
    he_green_md: 'rgb(136, 144, 80)',
    he_green_lt: 'rgb(175, 181, 138)',
    he_spruce: 'rgb(32, 81, 64)',
    he_spruce_md: 'rgb(88, 125, 112)',
    he_spruce_lt: 'rgb(143, 168, 159)',
    he_darkblue: 'rgb(11, 68, 107)',
    he_darkblue_md: 'rgb(72, 115, 144)',
    he_darkblue_lt: 'rgb(133, 161, 181)',
    he_purple: 'rgb(107, 11, 88)',
    he_purple_md: 'rgb(144, 72, 130)',
    he_purple_lt: 'rgb(181, 133, 171)'
};
export default colors;