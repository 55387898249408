import { Box, Button, RadioButtonGroup, Text } from "grommet";
import styled from "styled-components";

const StyledWideRadioButtonGroup = styled(RadioButtonGroup)`
  label {
    width: 100%;
  }
  label > div {
    width: 100%;
  }
`;

const WideRadioButtonGroup = (props) => {
  return (
    <StyledWideRadioButtonGroup
      pad={{ vertical: "small" }}
      direction="column"
      {...props}
    >
      {(option, { checked, focus, hover }) => {
        let background;
        if (checked) background = "brand";
        else if (focus || hover) background = "light-4";
        else background = "transparent";
        return (
          <Box background={background} border pad="xsmall" round="3px">
            {option.label}
          </Box>
        );
      }}
    </StyledWideRadioButtonGroup>
  );
};

const ActionButton = (props) => {
  return (
    <Button plain {...props} label={null} icon={null}>
      <Box
        background={!props.disabled ? "brand" : "light-4"}
        pad={{vertical:"xsmall",horizontal:"small"}}
        round="5px"
        direction="row"
        gap="xsmall"
        justify="center"
        height={{min:"2.5em"}}
        align="center"
      >
        {props.icon && props.icon}
        <Text pad={"none"} size="small" alignSelf="center">{props.label}</Text>
      </Box>
    </Button>
  );
};

const ModalButton = (props) => {
  return (
    <Button
      {...props}
      round="small"
      color={!props.disabled ? "dark-1" : "dark-3"}
      hoverIndicator={{
        background: {
          color: "dark-2",
        },
      }}
    />
  );
};

const StartOverButton = (props) => {
  return (
    <Button props={{ round: "small" }} plain {...props}>
      {({ disabled, hover, focus }) => {
        return (
          <Box
            round="5px"
            width="fit"
            border
            pad="xsmall"
            background={hover ? "rgba(150,50,50,1)" : "rgba(150,50,50,.1)"}
          >
            <Text textAlign="center" size="small">
              START OVER
            </Text>
          </Box>
        );
      }}
    </Button>
  );
};

export { WideRadioButtonGroup, ActionButton, StartOverButton, ModalButton };
