import { useContext, useEffect, useRef } from "react";
import appContext from "../context/appContext";
import { Box, Text } from "grommet";
import NumberedStep1 from "./LeftPane/Step_1";
import Map from "./Map/Map";
import { StartOverButton, ActionButton } from "./Buttons";
import { Download } from "grommet-icons";
import { geoLayers } from "../layers";

const MobileMain = () => {
  const {
    startOver,
    searchFeature,
    visibleGeoLayer,
    selectedGeos,
    setIsDownloadModalOpen,
  } = useContext(appContext);

  const mapContainerRef = useRef();

  useEffect(() => {
    if (mapContainerRef.current && visibleGeoLayer) {
      mapContainerRef.current.scrollIntoView();
    }
  }, [mapContainerRef, visibleGeoLayer]);
  return (
    <Box pad="small" gap="large">
      <Text size="large">{searchFeature.label}</Text>
      <Box>
        <Box>
          <Text>
            <strong>Select</strong> political boundaries for analysis.
          </Text>
        </Box>
        <Box pad={{ left: "large" }}>
          <NumberedStep1 />
        </Box>
      </Box>
      {visibleGeoLayer && (
        <Box>
          <Text>
            <strong>Tap</strong> on the map to select{" "}
            {geoLayers.find((gl) => gl.id === visibleGeoLayer).label2} for the
            report.
          </Text>
          <Box height="medium" pad={{ left: "large" }} ref={mapContainerRef}>
            <Map />
            <Box pad={{vertical:'medium'}}>
              Number of selected{" "}
              {geoLayers.find((gl) => gl.id === visibleGeoLayer).label2}:{" "}
              {selectedGeos.length}
            </Box>
          </Box>
          <ActionButton
            onClick={() => {
              setIsDownloadModalOpen(true);
            }}
            label="DOWNLOAD FULL REPORT"
            icon={<Download />}
            disabled={!selectedGeos || selectedGeos.length === 0}
          />
        </Box>
      )}

      <StartOverButton onClick={startOver} />
    </Box>
  );
};
export default MobileMain;
