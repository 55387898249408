import { useContext } from "react";

import appContext from "../../context/appContext";
import { useViewport } from "@headwaters-economics/web-shared";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import some from "lodash/some";
import econDataTypes from "../../assets/econDataTypes";
import numberFormatters from "../../numberFormatters";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Box } from "grommet";

const MapPopup = () => {
  const { screenSize } = useViewport();

  const {
    selectedGeos,
    activeStep,
    analysisType,
    epsData,
    selectedSocioEconomicType,
    hoveredFeature,
  } = useContext(appContext);

  const addOrRemove = some(selectedGeos, { id: hoveredFeature.id })
    ? "remove"
    : "add";
  const clickOrTap = screenSize === "mobile" ? "Tap" : "Click";

  const frag_header = () => {
    return (
      <div
        style={{ fontSize: "1em", fontWeight: 700, padding: "0.125em 1em 0" }}
      >
        {hoveredFeature.properties.name}
      </div>
    );
  };
  const frag_instructions = () => {
    if (activeStep === 2 && analysisType === "drawPolygon") return null;
    if (screenSize === "mobile" && activeStep === 3) return null;
    return (
      <div
        style={{
          fontSize: "0.9em",
          textTransform: "uppercase",
          letterSpacing: "2px",
          padding: "0 1em 0.125em",
          textAlign: "center",
        }}
      >
        {clickOrTap} to <b>{addOrRemove}</b>
      </div>
    );
  };
  const frag_epsData = () => {
    if (activeStep !== 3) return null;
    if (!epsData[hoveredFeature.id]) {
      return (
        <div
          style={{
            textAlign: "center",
          }}
        >
          <FontAwesomeIcon icon={faSpinner} spin size="2x" />
        </div>
      );
    }
    return (
      <>
        <table>
          {/* <thead>
                            <tr>
                                <th></th>
                                <th style={{ textAlign: "center" }}>Estimate</th>
                            </tr>
                        </thead> */}
          <tbody style={{ fontSize: "0.75em" }}>
            {Object.keys(econDataTypes).map((key) => {
              const typeInfo = econDataTypes[key];
              const data = epsData[hoveredFeature.id][key];

              // let borderColor = econDataTypes[selectedSocioEconomicType].color;
              // let borderColor2 = econDataTypes[selectedSocioEconomicType].color_lt;

              const formatter =  numberFormatters[econDataTypes[selectedSocioEconomicType].metric];
              const val = formatter.format(data.pct || data.value);
              const moe = formatter.format(data.pct_moe || data.moe);

              return (
                <tr
                  key={key}
                  className="app_popup-row"
                  style={{
                    color: key === selectedSocioEconomicType ? "#fff" : "#fff",
                  }}
                >
                  <td
                    width={170}
                    style={{
                      textAlign: "left",
                      backgroundColor:
                        key === selectedSocioEconomicType ? "#666" : "inherit",
                    }}
                  >
                    {typeInfo.label}{" "}
                  </td>
                  <td
                    width={90}
                    style={{
                      backgroundColor:
                        key === selectedSocioEconomicType ? "#777" : "inherit",
                    }}
                  >
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "50px 10px 30px",
                      }}
                    >
                      <div>
                        <b>{val}</b>
                      </div>
                      <div
                        style={{
                          // fontSize: "0.9em",
                          display: moe === 0 ? "none" : "inline",
                        }}
                      >
                        &nbsp;&plusmn;&nbsp;
                      </div>
                      <div
                        style={{
                          // fontSize: "0.9em",
                          display: moe === 0 ? "none" : "inline",
                          textAlign: "left",
                        }}
                      >
                        {moe}
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };
  return (
    <Box background="dark-1">
      {frag_header()}
      {frag_epsData()}
      {frag_instructions()}
    </Box>
  );
};

export default MapPopup;
