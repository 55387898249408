import { useContext } from "react";

import appContext from "../../context/appContext";
import { useViewport } from "@headwaters-economics/web-shared";

import { geoLayers } from "../../layers";

import { Box, Button, Text } from "grommet";
import { WideRadioButtonGroup } from "../Buttons";

const NumberedStep1 = () => {
  const { screenSize } = useViewport();
  const { visibleGeoLayer, setVisibleGeoLayer, setActiveStep } =
    useContext(appContext);

  return (
    <Box>
      {screenSize !== "mobile" && (
        <Box pad={{ vertical: "small" }}>
          <Text>
            <strong>Select</strong> political boundaries for analysis.
          </Text>
        </Box>
      )}

      <WideRadioButtonGroup
        name="StepOneButtonGroup"
        options={geoLayers.map((geoLayer) => {
          return { label: geoLayer.label, value: geoLayer.id };
        })}
        onChange={(evt) => {
          const newVal = evt.target.value;
          setVisibleGeoLayer(newVal);
        }}
        value={visibleGeoLayer}
      />

      {screenSize !== "mobile" && (
        <Box direction="row-reverse">
          <Button
            onClick={() => {
              setActiveStep(2, screenSize);
            }}
            disabled={!visibleGeoLayer}
          >
            <Box
              background={!visibleGeoLayer ? "light-4" : "brand"}
              pad={{ horizontal: "small", vertical: "xsmall" }}
              width="xsmall"
              round="3px"
            >
              Continue
            </Box>
          </Button>
        </Box>
      )}
    </Box>
  );
};
export default NumberedStep1;
