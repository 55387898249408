import { useContext } from "react";

import { Source, Layer } from "react-map-gl";
import colors from "../../colors";

import appContext from "../../context/appContext";

const UsfwsLayers = () => {
  const { searchFeature } = useContext(appContext);

  return (
    <>
      <Source id={"usfws"} type="vector" url={"mapbox://headmin.he-usfws-2023"} />
      <Layer
        id={"usfwsReference"}
        source-layer={"all-usfws-units"}
        beforeId="poi-scalerank4-l15"
        source="usfws"
        type="fill"
        paint={{
          "fill-color": colors.usfws_default_fill,
          "fill-outline-color": colors.usfws_default_stroke,
        }}
        filter={["==", "is_wmd", "false"]}
      />
      <Layer
        id={"usfwsReference_labels"}
        source-layer={"all-usfws-labels"}
        source="usfws"
        type="symbol"
        minzoom={8}
        paint={{
          "text-halo-color": colors.textHalo,
          "text-halo-width": 3,
          "text-color": colors.usfws_default_stroke,
          "text-halo-blur": 0,
        }}
        layout={{
          "text-field": "{name}",
          "text-transform": "uppercase",
          "text-letter-spacing": 0.1,
          "text-max-width": 12,
          "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Bold"],
          "text-padding": 10,
          "text-size": 8,
        }}
      />

      {searchFeature && searchFeature.owner === "usfws" && (
        <>
          <Layer
            id={"usfwsReference_selected_label"}
            source-layer={"all-usfws-labels"}
            source="usfws"
            type="symbol"
            minzoom={0}
            paint={{
              "text-halo-color": colors.textHalo,
              "text-halo-width": 2,
              "text-color": colors.dark,
              "text-halo-blur": 0,
            }}
            layout={{
              // "text-allow-overlap":true,
              "text-field": "{name}",
              "text-letter-spacing": 0.05,
              "text-max-width": 12,
              "text-font": ["Open Sans Bold", "Arial Unicode MS Regular"],
              "text-padding": 15,
              "text-size": 18,
            }}
            filter={["==", ["to-string", ["id"]], searchFeature.id]}
          />
          <Layer
            id={"usfwsReference_selected_line"}
            source-layer={"all-usfws-units"}
            beforeId="poi-scalerank4-l15"
            source="usfws"
            type="line"
            paint={{
              "line-color": colors.dark,
              "line-width": 4,
            }}
            filter={["==", ["to-string", ["id"]], searchFeature.id]}
          />
        </>
      )}
    </>
  );
};

export default UsfwsLayers;
