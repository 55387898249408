import { useContext, useRef } from "react";
import { useViewport } from "@headwaters-economics/web-shared";
import appContext from "../context/appContext";
import EPSForm from "@headwaters-economics/web-shared/components/EpsForm";
import find from "lodash/find";
import { geoLayers } from "../layers";
import { Box, Footer, Header, Heading, Layer } from "grommet";
import { Download as DownloadIcon } from "grommet-icons";
import {
  ModalButton,
} from "./Buttons";

const DownloadModal = () => {
  const {
    isDownloadModalOpen,
    setIsDownloadModalOpen,
    selectedGeos,
    visibleGeoLayer,
    benchmarkName,
    searchFeature,
    benchmarkGeo,
    setBenchmark,
  } = useContext(appContext);
  const epsFormRef = useRef();
  const { screenSize } = useViewport();
  if (!isDownloadModalOpen) return null;
  return (
    <Layer
      onEsc={() => setIsDownloadModalOpen(false)}
      onClickOutside={() => setIsDownloadModalOpen(false)}
    >
      <Box gap="small" width={"large"}>
        <Header pad={"xsmall"} justify="start" border="bottom">
          <DownloadIcon />
          <Heading>Download Report</Heading>
        </Header>
        <Box pad={"small"} gap="small">
          <EPSForm
            disabled={selectedGeos.length === 0}
            pdfOnly={screenSize==='mobile'}
            TemplateName={
              ["tract", "place", "tribal"].indexOf(visibleGeoLayer) > -1
                ? "usfws"
                : "usfws-extended"
            }
            requestSource="usfws-indicators"
            excelOnly={selectedGeos.length > 5}
            Group1_geoCodes={selectedGeos.map((geo) => {
              return geo.id;
            })}
            Group1_Aggregate={false}
            Group1_Region={false}
            Group1_Benchmark={false}
            Group2_overrideName={
              selectedGeos.length === 1
                ? selectedGeos[0].label
                : "Combined " + find(geoLayers, { id: visibleGeoLayer }).label
            }
            // Group3_geoCodes={[benchmarkGeo.id]}
            Group3_overrideName={benchmarkName}
            // printFormat="e"
            defaultTitle="USFWS Socioeconomic Profile"
            // defaultSubTitle={selectedGeos.length === 1 ? selectedGeos[0].label : "Selected " + find(geoLayers, { id: visibleGeoLayer }).label}
            defaultSubTitle={searchFeature.label}
            ref={epsFormRef}
            benchmarkGeo={benchmarkGeo}
            onBenchmarkGeoUpdate={setBenchmark}
          />
        </Box>
        <Footer pad={"xsmall"} border={"top"} justify="end">
          <ModalButton
            label="Download"
            // disabled={benchmarkGeo.id === "0"}
            onClick={() => {
              if (epsFormRef.current) {
                epsFormRef.current.submitViaCors();
              }
            }}
          />
          <ModalButton
            label="Close"
            onClick={() => setIsDownloadModalOpen(false)}
          />
        </Footer>
      </Box>
    </Layer>
  );
};
export default DownloadModal;
