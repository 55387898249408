import { useContext } from "react";
import { useViewport } from "@headwaters-economics/web-shared";
import appContext from "../context/appContext";
import { Box, Grid } from "grommet";
import Main from "../components/Main";
import LeftPane from "../components/LeftPane/Container";

import MobileMain from "../components/MobileMain";

import AppHeader from "../components/AppHeader";
import DownloadModal from "../components/DownloadModal";

const PrimaryScreen = () => {
  const { screenSize } = useViewport();
  const { isStartScreenVisible } = useContext(appContext);

  if (isStartScreenVisible) return null;

  if (screenSize === "mobile") {
    return (
      <Box fill='horizontal'>
        <AppHeader height={'65px'}/>
        <MobileMain />
        <DownloadModal />
      </Box>
    );
  }

  return (
    <Grid
      fill
      rows={["65px", "flex"]}
      columns={["auto", "flex"]}
      areas={[
        { name: "header", start: [0, 0], end: [1, 0] },
        { name: "sidebar", start: [0, 1], end: [0, 1] },
        { name: "main", start: [1, 1], end: [1, 1] },
      ]}
    >
      <AppHeader gridArea="header"/>
      <Box gridArea="sidebar" width="medium">
        <LeftPane />
      </Box>
      <Box gridArea="main" fill background={"#d3d3d3"}>
        <Main />
      </Box>
      <DownloadModal />
    </Grid>
  );
};
export default PrimaryScreen;
